<template>

</template>
<script>
import '@/untils/auth'
import { Dialog } from 'vant'
export default {
    name: 'pay',
    data() {
        return {
            goodsId: '',
            rechargeAmount: '',
            ownerId: '',
            outTradeNo: ''
        }
    },
    methods: {
        init() {
            let that = this;
            const { goodsId, rechargeAmount, ownerId, outTradeNo } = this
            let code = sessionStorage.getItem('code')
            // 获取直播间信息
            let timestamp = new Date().getTime()
            let p1_params = {
                payType: '1',
                rechargeAmount,
                tripartitePayTypeDetail: 'PUBLIC_JSAPI',
                h5ExtUrlParam: code,
                goodsId,
                moduleType: '2',
                payAccount: 'adapay_wx_gzh_radio',
                ownerId,
                from: 'H5',
                outTradeNo
            };
            let p2_password = keyTurnToPassword();
            let p1_params_new = paramsToP1(p1_params);
            let headers = {
                "p1": p1_params_new,
                "p2": p2_password,
                "timestamp": timestamp
            }
            console.log(p1_params, headers)

            this.$http.handleWxPay(p1_params, headers).then(msg => {
                if(msg.code == 1) {
                    let data = JSON.parse(msg.data.requestData)
                    let payData = JSON.parse(data.expend.pay_info)
                    // 支付
                    this.handlePay(payData)
                }else {
                    Dialog.alert({
                        title: '提示',
                        message: '操作失败，请点击网页“刷新二维码”后从新扫描',
                        confirmButtonColor: '#1890ff'
                    }).then(() => {
                        // on close
                        window.WeixinJSBridge.call('closeWindow')
                    });
                }
            })
        },
        handlePay(data) {
            console.log({...data})
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                appId: data.appId,
                timeStamp: data.timeStamp,
                nonceStr: data.nonceStr,
                package: data.package,
                signType: data.signType,
                paySign: data.paySign,
            }, res => {
                console.log('res:', res)
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    Toast('支付成功')
                    setTimeout(() => {
                        window.WeixinJSBridge.call('closeWindow')
                    }, 2000)
                }

                if(res.err_msg == 'get_brand_wcpay_request:cancel') {
                    Toast('取消支付')
                    setTimeout(() => {
                        window.WeixinJSBridge.call('closeWindow')
                    }, 2000)
                }
            })
        },

    },
    mounted() {
        const { goodsId, rechargeAmount, ownerId, outTradeNo } = this.$route.query
        this.goodsId = goodsId
        this.rechargeAmount = rechargeAmount
        this.ownerId = ownerId
        this.outTradeNo = outTradeNo
        let code = sessionStorage.getItem('code')
        if(code) {
            this.init()
        }
    }
}
</script>
