var ua = navigator.userAgent
let isWeChat = ua.toLowerCase().indexOf('micromessenger') !== -1
// 测试环境
let openid = localStorage.getItem('openid')


let code = geturlparam('code')
let appid = 'wxddc242fe7f3213e5'

function geturlparam(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r !== null) return unescape(r[2])
    return null
}

if(isWeChat && openid == null) {
    if (code == null || code === '') {
        window.location.href =
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
            appid +
            '&redirect_uri=' +
            encodeURIComponent(window.location.href) +
            '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    }else {
        sessionStorage.setItem('code', code)
    }
}
