// , put , deleteFun
import { get, post } from './http';
let api = '';

export const login = (params , header) => post(`${api}/v2/auth/login`, params , header); //登录接口
export const loginOut = (params , header) => post(`${api}/v2/auth/logout`, params , header); //退出登录
export const getUserInfo = (params , header) => get(`${api}/v2/user/info`, params , header); // 获取用户资料
export const getUserBalance = (params , header) => get(`${api}/v2/user/wallet/balance`, params , header); // 获取 用户余额


export const broadcastList = (params , header) => get(`${api}/v2/radio/live/broadcast/list`, params , header); // 获取直播间列表
export const baseLiveInfo = (params , header) => get(`${api}/v2/radio/live/base/info`, params , header); // 获取直播间信息
export const livetoken = (params , header) => post(`${api}/v2/radio/live/token/publisher`, params , header); // 获取 直播 声网 token
export const imLoginUserInfo = (params , header) => get(`${api}/v2/auth/user/loginUserInfo`, params , header); // 链接 腾讯 IM 后台 Token
export const giftList = (params , header) => get(`${api}/v1/radio/gift/list`, params , header); // 获取礼物列表
export const dealBatch = (params , header) => post(`${api}/v2/radio/gift/deal/batch`, params , header); //赠送 主播礼物
export const joinOrLeave = (params , header) => post(`${api}/v2/radio/live/join-or-leave/room`, params , header); // 加入或者离开直播间
export const memberOnlines = (params , header) => get(`${api}/v2/radio/live/member/onlines`, params , header); // 获取直播间在线用户
export const bannerObtain = (params , header) => get(`${api}/v2/banner/obtain`, params , header); // 获取直播 轮播图

export const topListByDateV2 = (params , header) => get(`${api}/v2/radio/gift/topListByDateV2`, params , header); // 观众财富榜（日/周、月）
export const anchorTopListByDateV2 = (params , header) => get(`${api}/v2/radio/gift/anchorTopListByDateV2`, params , header); // 主播音符榜（收入榜）（日/周、月）
export const anchorTopListByDateV2Copy = (params , header) => get(`${api}/v2/user/earning/revenueRank`, params , header); // 魅力榜 改
export const queryTopEarningRanking = (params , header) => get(`${api}/v2/family/queryTopEarningRanking`, params , header); // 家族流水榜（日/周、月）

export const getRankGlory = (params, header) => get(`${api}/v2/pk/rank/glory`, params, header); // 荣耀排行榜
export const getRankStarlight = (params, header) => get(`${api}/v2/pk/rank/starlight`, params, header); // 荣耀排行榜
export const handleWxPay = (params, header) => post(`${api}/v2/pay/recharge/adapay/wx/prepay`, params, header)
