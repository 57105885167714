/* eslint-disable */
// 在http.js中引入axios
import axios from 'axios'; // 引入axios
// import ElementUI from 'element-ui';

let unifiedInfo = '{"imei":"10AA7CBB-AF9C-4253-A437-9D2CA9DB31AF","marketChannel":"0","clientType":"1","version":"2.0.1","appType":"3"}';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //POST请求头
axios.defaults.timeout = 5000; //请求超时时间
// axios.defaults.baseURL = 'https://mobile.bjyixf.cn';  // 生产环境
// axios.defaults.baseURL = 'https://web.bjmante.cn';  // 测试环境
console.log('name:', location.hostname)
if (location.hostname == 'web1h5.bjmante.cn' || location.hostname == 'localhost') {
    axios.defaults.baseURL = 'https://mobile.bjmante.cn'; // 测试环境
    // axios.defaults.baseURL = 'https://mobile.bjyixf.cn'; // 生产环境
} else {
    axios.defaults.baseURL = 'https://mobile.bjyixf.cn'; // 生产环境
}
// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 每次发送请求之前判断vuex中是否存在token
        const token = localStorage.getItem('Authorization');
        if (token) {
            config.headers.Authorization = token;
        }
        // else{
        //     ElementUI.Message.error('未登录，请登录');
        // }
        config.headers.unified = unifiedInfo;
        config.headers.version = JSON.parse(unifiedInfo)['version'];
        config.headers.appType = JSON.parse(unifiedInfo)['appType'];
        config.headers.marketChannel = JSON.parse(unifiedInfo)['marketChannel'];
        config.headers.clientType = JSON.parse(unifiedInfo)['clientType'];
        config.headers.imei = JSON.parse(unifiedInfo)['imei'];

        return config;
    },
    (error) => {
        return Promise.error(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        if (response.status === 200) {
            let token = response.headers.token ? response.headers.token : '';
            if (token) {
                localStorage.setItem('Authorization', token);
            }
            if (response.data.p1) {
                // console.log(response)
                let res2 = rsadecrypt(response.data.p2);
                let msg = JSON.parse(decrypt(response.data.p1, res2));
                let data = { data: msg };
                // console.log(data)
                return Promise.resolve(data);
            } else {
                return Promise.resolve(response);
            }
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} header [请求时携带的头文件]
 */
export function get(url, params, header) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'get', // 默认是 get
            headers: header,
            params: params
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, header) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'post', // 默认是 get
            headers: header,
            data: params
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, JSON.stringify(params))
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deleteFun(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, params)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}
