<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: 'index',
    data() {
        return {
            windowWidth: 0
        };
    },
    watch: {
        windowWidth(val) {
            let that = this;
            // console.log("实时屏幕宽度：", val, that.windowWidth);
            // if (location.hostname == 'localhost') return;
            // if (that.windowWidth <= 980) {
            //     window.location.href = 'https://m.iyaoya.com';
            // } else {
            //     window.location.href = 'https://iyaoya.com';
            // }
            if (that.windowWidth > 980) {
                window.location.href = 'https://iyaoya.com/#/';
            }
        }
    },
    mounted() {
        var that = this;
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                window.fullWidth = document.documentElement.clientWidth;
                that.windowHeight = window.fullHeight; //获取屏幕高度
                that.windowWidth = window.fullWidth; //获取屏幕宽度
            })();
        };
    }
};
</script>
<style></style>
