<template>
    <div class="livePage">
        <div class="liveHeader">
            <div class="leftNav">
                <div class="leftIcon" @click="backIndex">
                    <van-icon name="arrow-left" color="#FFFFFF" />
                </div>
            </div>
            <div class="centerNav"></div>
            <div class="rightNav"></div>
        </div>
        <!-- 播放器 -->
        <div id="localPlayer" ref="localPlayer" class="player"></div>
        <!-- 弹幕区域 -->
        <div class="rightMsgContent" id="rightMsgContent">
            <div class="chartList" v-for="(item , index) in msgListArr" :key="index">
                <div class="levelImg" v-if="item.payload.msgType == 'hello' || item.payload.msgType == 'text' || item.payload.msgType == 'gift' || item.payload.msgType == 'live_emotions'">
                    <div class="levelDiv" v-if="item.payload.sendUserInfo.level">
                        <img src="../static/img/level.png" alt=""><span>{{item.payload.sendUserInfo.level}}</span>
                    </div>
                </div>
                <div v-else class="sysMsg">
                    <i class="el-icon-message-solid"></i>
                </div>
                <!-- <div class="userMingPai">
                    <img :src="require(`@/static/level/${levelMingPai(item.payload.sendUserInfo.level)}.png`)" alt="">
                </div> -->
                <div class="userAvatar" v-if="item.avatar">
                    <img :src="item.avatar" alt="" style="width: 20px; height: 20px" >
                </div>
                <div class="userAvatar" v-if="item.payload.sendUserInfo.face_url">
                    <img :src="item.payload.sendUserInfo.face_url" alt="" style="width: 20px; height: 20px" >
                </div>
                <div class="msgContent">
                    <div v-if="item.payload.msgType == 'gift'" class="giftMsgDiv">
                        <span class="orangeSpan">
                            {{item.payload.sendUserInfo.name}}
                        </span> 向 
                        <span class="orangeSpan">
                            {{item.payload.giftContent.receiveName}}
                        </span> 送出 
                        <img v-if="item.payload.giftContent.giftIcon" :src="item.payload.giftContent.giftIcon" alt="" style="width: 20px; height: 20px" >
                        {{item.payload.giftContent.giftName}} X
                        {{item.payload.giftContent.giftCount ? item.payload.giftContent.giftCount : 1}}
                    </div>
                    <div v-if="item.payload.msgType == 'hello'">
                        <span class="blueSpan">{{item.payload.sendUserInfo.name}}</span> 来了~
                    </div>
                    <div v-if="item.payload.msgType == 'text'">
                        <span class="blueSpan">{{item.payload.sendUserInfo.name}}</span>： {{item.payload.msgContent.text}}
                    </div>
                        <!-------- 表情消息 -------->
                        <div v-if="item.payload.msgType == 'live_emotions'">
                        <span class="blueSpan">{{item.payload.sendUserInfo.name}}</span>
                        <span class="redSpan">{{item.payload.expression.title}}</span>
                    </div>
                    <!-------- 幸运礼物中奖消息 -------->
                    <div v-if="item.payload.msgType == 'normal_luck_gift'">
                        系统消息：恭喜<span class="blueSpan">{{item.payload.sendUserInfo.name}}</span>获得
                        <img v-if="item.payload.luckyContent.giftIcon" :src="item.payload.luckyContent.giftIcon" alt="" style="width: 20px; height: 20px" >
                        <span class="orangeSpan">{{item.payload.luckyContent.giftTitle}}</span>
                        <span class="orangeSpan">{{item.payload.luckyContent.showTxt}}</span>
                    </div>
                    <div v-if="item.payload.msgType == 'lucky_gift'">
                        系统消息：恭喜<span class="blueSpan">{{item.payload.sendUserInfo.name}}</span>获得
                        <img v-if="item.payload.luckyContent.giftIcon" :src="item.payload.luckyContent.giftIcon" alt="" style="width: 20px; height: 20px" >
                        <span class="orangeSpan">{{item.payload.luckyContent.giftTitle}}</span>
                        <span class="orangeSpan">{{item.payload.luckyContent.showTxt}}</span>
                    </div>
                    <!-------- 关注消息 -------->
                    <div v-if="item.payload.msgType == 'system_userFollow'">
                        系统消息：<span class="blueSpan">{{item.payload.sendUserInfo.name}}</span>{{item.payload.msgContent.text ? item.payload.msgContent.text : '关注了主播'}}
                    </div>
                    <!-- suspendLive -->
                    <!-------- 结束直播 -------->
                    <div v-if="item.payload.msgType == 'endLive'">
                        <span class="blodSpan">直播已结束</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 发送文字 -->
        <div class="footerBottom">
            <div class="inputDiv">
                <van-field v-model="msgInput" placeholder="请输入发送内容" />
            </div>
            <div class="btnDiv">
                <van-button type="primary" @click="sendUserMsg">发送</van-button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default{
    name: 'livePage',
    data(){
        return{
            userId: localStorage.getItem('userId'),
            loginUserName: localStorage.getItem('loginUserName'),
            liveId: '',
            liveRoomInfo: {
                channelName: '',
                gid: '',
                liveId: ''
            },// 直播间信息
            livePlayToken: '',

            livePlayToken: '',
            roomUserTitle: ['在线用户' , '贵族' , '管理' , '亲密'],
            activeUserNum: 0,
            giftLuckArr: [{}],//幸运礼物数组
            giftTileArr: [{}],//幸运礼物标题数组
            activeTitleNum: 0,//标题状态
            activeGift: 0,//选中礼物高亮
            userInputGitNum: 1,//用户输入 送礼物数量
            leftGiftGuangGaoArr: [],//左侧 底部 全屏广告 数组
            liveUserArr: [],//直播间在线用户数组
            activeMsg: 0,// 全部消息  私信 状态
            msgInput: '',//聊天输入 内容
            msgListArr: [],//消息对象数组
            giftListArr: [],//收到的礼物数组
            clearGigtTimer: null, // 动态清空 左侧 礼物展示 定时器
            ImChart: '',//腾讯IM对象
            userBalance: 0,//用户余额
            bannerArr: [],//直播间轮播图
            userInfoMation: {},//用户详情
            totalItem: 0,//直播间总人数
        }
    },
    mounted() {
        if(localStorage.getItem('liveNum')){
            localStorage.removeItem('liveNum');
            window.location.reload();
        }
        this.liveId = this.$route.query.liveId;
        this.getLiveInfo();// 获取直播间信息
    },
    updated(){
        // 在接收到新消息的时候触发方法将滚动条定位到底部
        this.scrollToBottom();
        // this.giftSpliceFun();//更新礼物 效果消息
    },
    beforeDestroy(){
        localStorage.removeItem('liveNum');
    },
    methods:{
        getLiveInfo: function () {
            // 获取直播间信息
            let that = this;
            let timestamp = new Date().getTime()
            let p1_params = {
                timestamp: timestamp,
                liveId: that.liveId
            };
            let p2_password = keyTurnToPassword();
            let p1_params_new = paramsToP1(p1_params);
            let headers = {
                "p1": p1_params_new,
                "p2": p2_password,
                "timestamp": timestamp
            }
            this.$http.baseLiveInfo(p1_params, headers).then(msg => {
                if (msg.code == 1) {
                    console.log('获取直播间信息', msg)
                    this.liveRoomInfo = msg.data;
                    // this.bannerObtain();//获取直播间轮播图
                    this.joinOrLeave(1);//加入房间
                    this.getLiveToken();//获取播放 token
                    this.getImUserInfo();//获取 腾讯IM token
                    // this.getGiftList();//获取礼物列表
                    // setInterval(()=>{
                    //     this.getOnlineUser();//获取直播间在线用户
                    // },3000)
                    // this.getUserFunInfo();//获取当前用户个人资料
                    // this.getUserBalance();//获取用户余额
                }
            })
        },
        getLiveToken: function(){
            // 获取 直播 声网 token
            let that = this;
            let timestamp = new Date().getTime();
            let p1_params = {
                channelName: that.liveRoomInfo.channelName,
                uid: that.userId,
                timestamp: timestamp
            };

            let p2_password = keyTurnToPassword();
            let p1_params_new = paramsToP1(p1_params);
            let header = {
                "p1": p1_params_new,
                "p2": p2_password,
                "timestamp": timestamp
            }
            this.$http.livetoken(p1_params , header).then((msg)=>{
                if(msg){
                    this.livePlayToken = msg.data;
                    // 播放声网 视频
                    liveToken(that.liveAppId , msg.data , that.liveRoomInfo.channelName);
                }
            })
        },
        getImUserInfo: function(){
            // 链接 腾讯 IM 后台 Token
            let timestamp = new Date().getTime()
            let p1_params = {
                timestamp: timestamp
            };
            let p2_password = keyTurnToPassword();
            let p1_params_new = paramsToP1(p1_params);
            let header = {
                "p1": p1_params_new,
                "p2": p2_password,
                "timestamp": timestamp
            }
            this.$http.imLoginUserInfo(p1_params , header).then((msg) => {
                if(msg.code == 1){
                    let imSign = msg.data.imSign;
                    // let netEaseImPojo = msg.data.netEaseImPojo;
                    // let timPojo = msg.data.timPojo;
                    this.getImConnect(imSign); // 链接腾讯云IM
                }
            })
        },
        async getImConnect(userSig){
            // 链接腾讯云IM
            let appId = "1400228543";
            let options = {
                SDKAppID: appId // 接入时需要将0替换为您的即时通信应用的 SDKAppID
            };
            this.ImChart = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示
            this.ImChart.setLogLevel(4); // 普通级别，日志量较多，接入时建议使用
            this.ImChart.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
            await this.imLogin(userSig);//腾讯 IM 登录
        },
        async imLogin(userSig){
            // 腾讯 IM 登录
            let that = this;
            let promise = this.ImChart.login({userID: that.userId, userSig: userSig});
            promise.then(function(imResponse) {
                if(imResponse.data){
                    that.joinGroup();//加入群组
                    if (imResponse.data.repeatLogin === true) {
                    }
                }
            }).catch(function(imError) {
                console.warn('login error:', imError); // 登录失败的相关信息
            });
        },
        joinGroup: function(){
            // 加入腾讯IM群组
            let promise = this.ImChart.joinGroup({ groupID: this.liveRoomInfo.gid });
            promise.then(function(imResponse) {
                switch (imResponse.data.status) {
                    case TencentCloudChat.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                break;
                    case TencentCloudChat.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                break;
                    case TencentCloudChat.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                break;
                    default:
                break;
            }
            }).catch(function(imError){
            });
            setTimeout(() => {
                this.joinRoomMsg();//加入房间消息
            }, 1500);
        },
        onMessageReceived: function(event){
            // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
            let msg = event.data[0];
            let payload = JSON.parse(msg.payload.data);
            msg.payload = payload;
            // console.log('msg.payload',msg.payload)
            // console.log('IM推送过来的消息', event);
            if(this.liveRoomInfo.liveId != msg.payload.liveId && this.liveRoomInfo.liveId != msg.payload.liveContent.liveId){
                // 阻止掉 其他直播间消息
                return
            }
            // 本地缓存消息数量
            if(this.msgListArr.length > 500){
                this.msgListArr.splice(0,1);
            }
            console.log(msg)
            // 过滤掉中奖礼物超过 500 倍以上的 大奖
            if(msg.payload.msgType == "normal_luck_gift" || msg.payload.msgType == "lucky_gift"){
                let liveTxt = msg.payload.luckyContent.rateTitle[0];
                let leftNum = liveTxt.indexOf('获得');// console.log('中奖礼物超过 500 倍以上的 大奖',leftNum);
                let rightNum = liveTxt.indexOf('倍');// console.log('中奖礼物超过 500 倍以上的 大奖',rightNum);
                let beiShu = liveTxt.substring(leftNum+2,rightNum);// console.log('中奖礼物超过 500 倍以上的 大奖',beiShu);
                if(Number(beiShu) >= 500){
                    msg.payload.luckyContent['showTxt'] = `${beiShu}倍大奖！`
                    this.leftGiftGuangGaoArr.push(msg);
                }else{
                    msg.payload.luckyContent['showTxt'] = `${beiShu}倍大奖！`
                    this.msgListArr.push(msg);
                }
            }else{
                this.msgListArr.push(msg);
            }

            // 缓存礼物消息
            if(msg.payload.msgType == "gift"){
                // 送礼物消息
                this.giftListArr.push(msg);
                if(msg.payload.giftContent.giftUrl){
                    this.playSVGAFun(msg.payload.giftContent.giftUrl);
                }
            }
        },
        joinRoomMsg: function(){
            // 加入直播间消息
            let that = this;
            let timestamp = new Date().getTime()
            // 用户发送消息
            let msgInfo = {
                "callRoomId":0,
                "callState":0,
                "chatType":"group",
                "conversationId": that.liveRoomInfo.gid,
                "liveId": that.liveRoomInfo.liveId,
                "msgContent":{},
                "msgType":"hello",
                "sendUserInfo":{
                    "face_url": that.userInfoMation.headPortrait,
                    "id": that.userInfoMation.userId,//that.userInfoMation.virtualId
                    "isMember":false,
                    "isNew": this.userInfoMation.isNew,
                    "level": that.userInfoMation.level,
                    "name": that.loginUserName
                },
                "timestamp": timestamp
            }

            // 2. 创建消息实例，接口返回的实例可以上屏
            let message = that.ImChart.createCustomMessage({
                to: that.liveRoomInfo.gid,
                conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                payload: {
                    data: JSON.stringify(msgInfo), // 自定义消息的数据字段
                    description: '', // 自定义消息的说明字段
                    extension: '', // 自定义消息的扩展字段
                }
            });
            // 3. 发送消息
            let promise = that.ImChart.sendMessage(message);
            promise.then(function(imResponse) {
                // 发送成功
                // console.log('发送成功',imResponse);
                let payload = {
                    payload: msgInfo
                }
                console.log(payload)
                that.msgListArr.push(payload);
            }).catch(function(imError) {
                // 发送失败
                console.warn('sendMessage error:', imError);
            });
        },
        sendUserMsg: function(){
            let that = this;
            let timestamp = new Date().getTime()
            // 用户发送消息
            let msgInfo = {
                "callRoomId":0,
                "callState":0,
                "chatType":"group",
                "conversationId": that.liveRoomInfo.gid,
                "liveId": that.liveRoomInfo.liveId,
                "msgContent":{
                    "shopId":0,
                    "skuId":0,
                    "text": that.msgInput
                },
                "msgType":"text",
                "sendUserInfo":{
                    "admissionDynamicEffect":"",
                    "admissionDynamicEffectName":"",
                    "chatBubble":"",
                    "fontColor":"#ffffff",
                    "icon": that.userInfoMation.headPortrait,
                    "face_url": that.userInfoMation.headPortrait,
                    "id": that.userInfoMation.userId,//  that.userInfoMation.virtualId
                    "isMember":false,
                    "isNew": this.userInfoMation.isNew,
                    "isVip": 1,
                    "level": that.userInfoMation.level,
                    "name": that.loginUserName
                },
                "timestamp": timestamp
            }
            // 2. 创建消息实例，接口返回的实例可以上屏
            let message = that.ImChart.createCustomMessage({
                to: that.liveRoomInfo.gid,
                conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                payload: {
                    data: JSON.stringify(msgInfo), // 自定义消息的数据字段
                    description: '', // 自定义消息的说明字段
                    extension: '', // 自定义消息的扩展字段
                }
            });
            // 3. 发送消息
            let promise = that.ImChart.sendMessage(message);
            promise.then(function(imResponse) {
                // 发送成功
                // console.log('发送成功',imResponse);
                let payload = {
                    payload: msgInfo
                }
                that.msgInput = '';//清空输入内容
                that.msgListArr.push(payload);
            }).catch(function(imError) {
                // 发送失败
                console.warn('sendMessage error:', imError);
            });
        },
        scrollToBottom: function(){
            // 定义将滚动条定位在底部的方法
            this.$nextTick(()=>{
                try{
                    let chat = this.$el.querySelector("#rightMsgContent");
                    chat.scrollTop = chat.scrollHeight;

                    // let chatGift = this.$el.querySelector("#giftContent");
                    // chatGift.scrollTop = chatGift.scrollHeight;

                    // let guangGaoDiv = this.$el.querySelector("#guangGaoDiv");
                    // guangGaoDiv.scrollTop = guangGaoDiv.scrollHeight;
                }catch(err){}
            })
        },
        joinOrLeave: function(flag){
            // 加入或者离开房间
            let that = this;
            let timestamp = new Date().getTime();
            let p1_params = {
                userId: that.userId,
                anchorId: that.liveRoomInfo.virtualId,
                flag: flag,
                liveId: that.liveRoomInfo.liveId,
                timestamp: timestamp
            }

            let p2_password = keyTurnToPassword();
            let p1_params_new = paramsToP1(p1_params);
            let headers = {
                "p1": p1_params_new,
                "p2": p2_password,
                "timestamp": timestamp
            }
            this.$http.joinOrLeave(p1_params , headers).then(res=>{
                if(res.code == 1){

                }
            })
        },
        backIndex: function(){
            // 返回首页
            this.$router.push('index');
        },
    }
}
</script>
<style>
.player{
  height: 100vh;
}
</style>
<style lang="less" scoped>
.livePage{
    position: absolute;
    width: 100%;
    height: 100%;
    .liveHeader{
        height: 45px;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftNav{
            margin-left: 10px;
            .leftIcon{
                width: 35px;
                height: 35px;
                background: rgba(0, 0, 0, .3);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    #localPlayer{
        width: 100vw;
        height: 100vh;
        position: relative;
    }
    .player{
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 1;
    }
    .rightMsgContent{
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 55px;
        height: 40vh;
        z-index: 9;
        /* 聊天信息 */
        .chartList{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 0 5px 5px;
            font-size: 14px;
            word-break: break-all;
            padding: 5px 15px 5px 8px;
            .levelImg{
                .levelDiv{
                    background: linear-gradient(to right, #5FD0D3, #45B9D0);
                    padding: 0.01rem 0.1rem;
                    border-radius: 0.1rem;
                    margin-left: 0.05rem;
                    color: #E5D451;
                    font-style: italic;
                    font-weight: bold;
                    img{
                        width: 0.18rem;
                        height: 0.16rem;
                    }
                    span{
                        margin-left: -0.05rem;
                    }
                }
            }
            .userMingPai{
                display: flex;
                align-items: center;
                img{
                    width: 0.5rem;
                    height: 0.19rem;
                    margin-left: 0.05rem;
                }
            }
            .sysMsg{
                color: #AFCEFF;
                font-size: 16px;
            }
            .userAvatar{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin: 0 0 0 0.05rem;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .msgContent{
                font-size: 14px;
                padding: 0 3px;
                color: white;
                >div{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    word-break: break-all;
                    span{
                        margin: 0 0.05rem;
                    }
                }
                .giftMsgDiv{
                    display: flex;
                    align-items: center;
                    .el-image{
                        margin: 0 0.05rem;
                    }
                }
                .orangeSpan{
                    color: #E5D451;
                }
                .blueSpan{
                    color: #AFCEFF;
                }
                .redSpan{
                    color: #E83123;
                }
                .blodSpan{
                    font-weight: bold;
                    color: red;
                }
            }
        }
    }
    .footerBottom{
        position: fixed;
        bottom: 0;
        width: calc(100% - 10px);
        left: 0;
        height: 45px;
        padding: 5px;
        z-index: 9;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .inputDiv{
            width: 82%;
        }
        .btnDiv{
            width: 15%;
            margin-left: 5px;
        }
    }
}
</style>