import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/vant.js';

import * as httpRequest from './untils/api';
Vue.prototype.$http = httpRequest;
import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.prototype.liveAppId = '30032fb6e8784cb88d5941683661d678';//声网 播放 ID

Vue.config.productionTip = false

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
