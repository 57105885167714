import Vue from 'vue';
import { Button, Popover, Toast, Swipe, SwipeItem, Grid, GridItem, Image, Icon, Field, NavBar, CellGroup } from 'vant';
import 'vant/lib/index.css';
Vue.use(Button);
Vue.use(Popover);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Icon);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(CellGroup);

// 封装全局方法
// 打开加载
window.startLoading = function () {
    Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
    });
};
//关闭加载
window.endLoading = function () {
    Toast.clear();
};

window.Toast = function (msg) {
    Toast(msg);
};
