<!-- 首页 -->
<template>
    <div class="home-index">
        <div class="swipe-box">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in swiperData" :key="index">
                        <div class="ranking-box" id="ranking-box" :data-pageId="item.pageId">
                            <img class="user-head" :src="`./images/${index+1}.png`" alt="" :data-pageId="item.pageId" />
                            <h3 :data-pageId="item.pageId">{{ item.title }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import Swiper from 'swiper';

export default {
    data() {
        return {
            swiper: null,
            swiperData: [
                { title: '魅力榜', pageId: 1 },
                { title: '土豪榜', pageId: 2 },
                { title: '家族榜', pageId: 3 },
                { title: '星光榜', pageId: 4 },
                { title: '荣耀榜', pageId: 5 }
            ]
        };
    },

    activated() {},

    created() {},

    mounted() {
        this.swiper = new Swiper('.swiper-container', {
            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            nested: true,
            on: {
                click: (e) => {
                    let cardDataJSON = e.target.getAttribute('data-pageid');
                    this.toDetails(cardDataJSON);
                }
            }
        });
    },

    methods: {
        toDetails(pageId) {
            this.$router.push({ name: 'rankingList', query: { pageId: pageId } });
        }
    },

    filters: {},

    watch: {},

    //离开路由，不可截停
    destroyed() {},

    //离开路由，可截停
    beforeRouteLeave(to, from, next) {
        next();
    }
};
</script>

<style lang="less" scoped>
//显示一行，超出省略号
.show-text-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
//显示多行，超出省略号
.show-text-num(@num) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @num;
    overflow: hidden;
    word-break: break-all;
}
// 抛弃行高，文字居中写法全居中
.all_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-index {
    height: 100vh;
    width: 100vw;
    background: url(../../static/img/rank_index_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    font-size: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
.swipe-box {
    width: 100%;
    height: 9rem;
    position: absolute;
    left: 0;
    top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .ranking-box {
        width: 3.27rem;
        height: 4.16rem;
        background: url(../../static/img/rank_icon1.png);
        background-size: 100% 100%;
        text-align: center;
        margin: 0 auto;
        .user-head {
            width: 2.6rem;
            // height: 2.087rem;
            border-radius: 50%;
            // margin: 0.887rem auto 0.45rem auto;
        }
        h3 {
            color: #ffdebc;
            font-size: 0.507rem;
        }
    }
}
.swiper-slide {
    width: 60% !important;
    // margin: 0 5%;
}
</style>
