import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index.vue';
import live from '../views/live.vue';
import login from '../views/login.vue';
import ranking from '../views/ranking/index.vue';
import rankingDetails from '../views/ranking/details.vue';
import pay from '../views/pay'

Vue.use(VueRouter);

const routes = [
    { path: '/', name: 'index', component: index },
    { path: '/index', name: 'index', component: index },
    { path: '/live', name: 'live', component: live },
    { path: '/login', name: 'login', component: login },
    { path: '/ranking', name: 'ranking', component: ranking, meta: { keepAlive: true } },
    { path: '/rankingList', name: 'rankingList', component: rankingDetails },
    { path: '/pay', name: 'pay', component: pay }
];

const router = new VueRouter({
    routes
});

let originPush = VueRouter.prototype.push; //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else {
        //如果没有传回调函数，手动添加
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        );
    }
};

export default router;
