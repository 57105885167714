<template>
    <div>
        <van-nav-bar title="登录/注册" left-arrow @click-left="onClickLeft"/>
        <div class="loginContent">
            <van-cell-group>
                <van-field v-model="loginForm.mobile" type="tel" label="手机号" />
                <!-- 输入密码 -->
                <van-field v-model="loginForm.password" type="password" label="密码" />
            </van-cell-group>
            <div class="blockBtn">
                <van-button type="primary" @click="pwdLogin" block>登录</van-button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default{
    name: 'loginPage',
    data(){
        return{
            loginForm: {
                mobile: '',
                password: '',
            }
        }
    },
    methods:{
        pwdLogin: function(){
            // 账号密码登录
            let that = this;
            let timestamp = new Date().getTime();
            let params = {
                mobile: that.loginForm.mobile,
                password: that.loginForm.password, 
                timestamp: timestamp
            }
            this.$http.login(params, {}).then(res=>{
                if(res.code == 1){
                    that.loginUserName = res.data.nickName;
                    localStorage.setItem('loginUserName' , res.data.nickName);
                    localStorage.setItem('userId' , res.data.userId);
                    that.showLoginFlag = false;
                    that.$router.push('index');
                }else{
                   
                }
            })
        },
        onClickLeft: function(){
            this.$router.push('index');
        },
    }
}
</script>
<style>
.van-nav-bar__left .van-icon{
    color: #333333!important;
}
</style>
<style lang="less">
.loginContent{
    width: 90%;
    margin: 0 auto;
    .blockBtn{
        margin-top: 20px;
    }
}
</style>