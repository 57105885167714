<template>
    <div class="indexPage">
        <!-- 首页导航栏 -->
        <div class="homeNavBar">
            <div class="leftNav" @click="gotoHome">
                <img :src="require('../static/img/logo.png')">
                <span>妖妖直播</span>
            </div>
            <div class="rightNav">
                <label>登录/注册</label>
            </div>
        </div>
        <div class="emptyNav"></div>
        <!-- 直播列表 -->
        <div class="liveGrid">
            <van-grid :column-num="2">
                <van-grid-item v-for="(item , index) in liveListArr" :key="index" @click="getLiveToken(item)">
                    <van-image slot="icon" width="100%" height="120px" fit="cover" :src="item.headPortrait"/>
                    <div slot="text" class="textDiv">
                        <img src="../static/img/play_ico.png" alt="">
                        <span>{{ item.liveTitle }}</span>
                    </div>
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default{
    name: 'indexPage',
    data(){
        return{
            liveListArr: []
        }
    },
    mounted() {
        this.liveVideoFun();//获取直播间列表
    },
    methods: {
        liveVideoFun: function () {
        // 获取直播间列表
        let that = this;
        let timestamp = new Date().getTime()
        let p1_params = {
            tag: '',
            page: 1,
            pageSize: 10,
            liveType: 2,
            timestamp: timestamp
        };

        let p2_password = keyTurnToPassword();
        let p1_params_new = paramsToP1(p1_params);
        let headers = {
            "p1": p1_params_new,
            "p2": p2_password
        }
        this.$http.broadcastList(p1_params, headers).then((msg) => {
            if (msg.code == 1) {
                    that.liveListArr = msg.data.list;
                    console.log(that.liveListArr)
                }else{
                    // Toast.error(res.info);
                }
            })
        },
        getLiveToken: function (item) {
            // 点击 主播列表 获取声网token
            if (localStorage.getItem('Authorization') && localStorage.getItem('Authorization') != 'undefined' && localStorage.getItem('Authorization') != '' && localStorage.getItem('Authorization') != null) {
                // this.$router.push(`live?liveId=${item.liveId}`);
                // &uid=${item.uid}
                // let routeData = this.$router.resolve({ 
                //     path: "/live",
                //     query: { 
                //         liveId: item.liveId,
                //         virtualId: item.virtualId,
                //     }
                // });
                localStorage.setItem('liveNum',1);
                this.$router.push(`/live?liveId=${item.liveId}&virtualId=${item.virtualId}`);
                // window.open(routeData.href, '_blank');
            } else {
                this.$router.push('/login');
                // this.$toast.error('请登录');
            }
        },
        gotoHome: function(){
            this.$router.push('/');
        }
    }
}
</script>
<style>
.indexPage .van-grid-item__content{
    padding: 16px 0;
}
.indexPage .van-grid-item__icon-wrapper{
    width: 90%;
}
</style>
<style lang="less" scoped>
.indexPage{
    .homeNavBar{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        height: 45px;
        background: white;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9;
        .leftNav{
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width: 40px;
                height: 40px;
                margin: 0 10px;
            }
            span{
                font-style: italic;
                font-weight: bold;
                font-size: 14px;
            }
        }
        .rightNav{
            margin-right: 10px;
        }
    }
    .emptyNav{
        height: 45px;
    }
    .liveGrid{
        .textDiv{
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
        }
    }
}

</style>