<!-- 首页 -->
<template>
    <div class="home-index">
        <div class="top-ranking">
            <div class="top-head">
                <span><img @click="$router.go(-1)" src="../../static/img/rank_icon2.png" alt="" /></span>
                <div>{{ pageTitle }}</div>
                <van-popover v-model="showPopover" trigger="click" placement="bottom-end" :actions="actions" @select="onSelect">
                    <template #reference>
                        <span>
                            {{ actionTime.text }}
                            <img src="../../static/img/rank_icon3.png" alt="" />
                        </span>
                    </template>
                </van-popover>
            </div>
            <div class="user-Three">
                <div class="user-2" v-if="userData && userData[1]">
                    <div>
                        <img class="user-bg" src="../../static/img/rank_icon5.png" alt="" />
                        <img class="user-head" :src="userData[1].avatar" alt="" />
                    </div>
                    <h3>{{ pageId && pageId > 3 ? userData[1].nickname : userData[1].name }}  <span style='display: block; color: #000;margin-top: 0.3rem; font-size: 10px' >距上一名：{{ userData[1].distance > 10000 ? (userData[1].distance/10000).toFixed(0) : userData[1].distance }} {{ userData[1].distance > 10000 ? '万' : '' }}</span></h3>
                </div>

                <div class="user-1" v-if="userData && userData[0]">
                    <div>
                        <img class="user-bg" src="../../static/img/rank_icon4.png" alt="" />
                        <img class="user-head" :src="userData[0].avatar" alt="" />
                    </div>
                    <h3>
                        {{ pageId && pageId > 3 ? userData[0].nickname : userData[0].name }}
                    </h3>
                </div>
                <div class="user-3" v-if="userData && userData[2]">
                    <div>
                        <img class="user-bg" src="../../static/img/rank_icon6.png" alt="" />
                        <img class="user-head" :src="userData[2].avatar" alt="" />
                    </div>
                    <h3>{{ pageId && pageId > 3 ? userData[2].nickname : userData[2].name }}  <span style='display: block; color: #000;margin-top: 0.3rem; font-size: 10px' >距上一名：{{ (userData[2].distance/10000).toFixed(0) }} {{ userData[2].distance > 10000 ? '万' : '' }}</span></h3>
                </div>
            </div>
        </div>
        <div class="item-box" v-show="userData && userData.length > 3">
            <div class="item-data" v-for="(item, index) in userData" v-if="index > 2">
                <div class="item-data-l">
                    <span>{{ index + 1 }}</span>
                    <img :src="item.avatar" alt="" />
                    <span>{{ pageId && pageId > 3 ? item.nickname : item.name }}</span>
                </div>
<!--                <div class="item-data-r" v-show="pageId && pageId > 3">距上一名：{{ item.gloryValueDiff || item.scoreDiff || 0 }}</div>-->
                <div class="item-data-r" >距上一名：{{ item.distance > 10000 ? (item.distance/10000).toFixed(0) : item.distance }} {{ item.distance > 10000 ? '万' : '' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPopover: false,
            actions: [],
            actions1: [
                { text: '日榜', value: 1 },
                { text: '周榜', value: 2 },
                { text: '月榜', value: 3 }
            ],
            actions2: [
                { text: '周榜', value: 'week' },
                { text: '月榜', value: 'month' }
            ],
            actionTime: null,
            pageTitle: '',
            userData: [],
            actInterface: '',
            pageId: null
        };
    },

    activated() {},

    created() {
        this.setTitle();
        this.actionTime = this.actions[0];
        this.getData();
    },

    mounted() {},

    methods: {
        getData() {
            let that = this;
            let timestamp = new Date().getTime();
            let p1_params = {
                // timestamp: timestamp,
                type: that.actionTime.value
            };
            let p2_password = window.keyTurnToPassword();
            let p1_params_new = window.paramsToP1(p1_params);
            let header = {
                p1: p1_params_new,
                p2: p2_password,
                timestamp: timestamp
            };
            that.$http[that.actInterface](p1_params, header).then((res) => {
                console.log('数据返回', res);
                if (res.code == 1 && res.data) {
                    if (res.data.length > 0) {
                        that.userData = res.data;
                        console.log('userData:', that.userData)
                    } else {
                        window.Toast('暂无排行数据');
                    }
                } else {
                    that.userData = [];
                    window.Toast(res.info);
                }
            });
        },
        setTitle() {
            this.pageId = this.$route.query.pageId * 1;
            switch (this.pageId) {
                case 1:
                    this.pageTitle = '魅力榜';
                    this.actInterface = 'anchorTopListByDateV2Copy';
                    this.actions = this.actions1;
                    break;
                case 2:
                    this.pageTitle = '土豪榜';
                    this.actInterface = 'topListByDateV2';
                    this.actions = this.actions1;

                    break;
                case 3:
                    this.pageTitle = '家族榜';
                    this.actInterface = 'queryTopEarningRanking';
                    this.actions = this.actions1;

                    break;
                case 4:
                    this.pageTitle = '星光榜';
                    this.actInterface = 'getRankStarlight';
                    this.actions = this.actions2;

                    break;
                case 5:
                    this.pageTitle = '荣耀榜';
                    this.actInterface = 'getRankGlory';
                    this.actions = this.actions2;

                    break;

                default:
                    this.pageTitle = '魅力榜';
                    this.actInterface = 'anchorTopListByDateV2';
                    this.actions = this.actions1;
                    break;
            }
        },
        onSelect(action) {
            this.actionTime = action;
            this.getData();
        }
    },

    filters: {},

    watch: {},

    //离开路由，不可截停
    destroyed() {},

    //离开路由，可截停
    beforeRouteLeave(to, from, next) {
        next();
    }
};
</script>

<style lang="less" scoped>
//显示一行，超出省略号
.show-text-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
//显示多行，超出省略号
.show-text-num(@num) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @num;
    overflow: hidden;
    word-break: break-all;
}
// 抛弃行高，文字居中写法全居中
.all_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-index {
    height: 100vh;
    width: 100vw;
    background: #fff;
    font-size: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
.top-ranking {
    width: 100%;
    height: 5.6rem;
    background: url(../../static/img/rank_icon7.png);
    background-size: 100% 100%;
    .top-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.88rem;
        padding: 0 0.3rem;
        div {
            font-size: 0.32rem;
            color: #ffffff;
        }
        img {
            width: 0.44rem;
            height: auto;
            vertical-align: top;
        }
        span {
            display: block;
            width: 1.1rem;
            font-size: 0.28rem;
            color: #fff;
        }
    }
}
.user-Three {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    .user-1,
    .user-2,
    .user-3 {
        width: 33.33%;
        text-align: center;
        div:first-of-type {
            width: 2rem;
            height: 2rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-items: center;
            margin: 0 auto;
            .user-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            .user-head {
                width: 1.4rem;
                height: 1.4rem;
                margin: 0 auto;
                border-radius: 50%;
            }
        }
        h3 {
            font-size: 0.26rem;
            color: #ffffff;
            font-weight: 500;
            margin-top: 0.2rem;
        }
    }
    .user-2,
    .user-3 {
        margin-top: 0.3rem;
    }
}
.item-box {
    position: relative;
    width: 100%;
    height: calc(100% - 5.5rem);
    border-radius: 10px 10px 0 0;
    z-index: 2;
    margin-top: -0.1rem;
    background: #fff;
    overflow-y: auto;
    .item-data {
        height: 1.2rem;
        padding: 0.2rem 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .item-data-l {
            display: flex;
            align-items: center;
            span {
                font-weight: 500;
                color: #333333;
                font-size: 0.32rem;
                &:nth-child(1) {
                    font-size: 0.28rem;
                }
            }
            img {
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                margin-left: 0.33rem;
                margin-right: 0.2rem;
            }
        }
        .item-data-r {
            font-size: 0.24rem;
            font-weight: 500;
            color: #222222;
        }
    }
}
</style>
